<template>
  <div>
    <v-app-bar app fixed class="white">
      <v-toolbar flat color="transparent">
        <v-app-bar-nav-icon @click.stop="drawer2 = !drawer2" v-if="$vuetify.breakpoint.mobile"></v-app-bar-nav-icon>
        <v-toolbar-title>{{
          capitalizeFirstLetter($route.name)
        }}</v-toolbar-title>
      </v-toolbar>
    </v-app-bar>

    <!-- desktop -->
    <div v-if="!$vuetify.breakpoint.mobile">
      <v-navigation-drawer app left permanent v-model="drawer">
        <v-list>
          <v-img src="@/assets/logo-transparent.png" lazy-src="@/assets/logo-transparent.png" width="150px"
            class="mx-auto">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="#E96324"></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ userName }}
              </v-list-item-title>
              <!-- <v-list-item-subtitle>{{ userRole }}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item-group active-class="border" color="red">
            <v-list-item v-for="(item, index) in items" :key="index" :to="item.link">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!-- signout  -->
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block @click="signout()" color="#E96324" class="white--text">
              Logout
              <v-icon small right>mdi mdi-logout</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <!-- mobile -->
    <div v-if="$vuetify.breakpoint.mobile">
      <v-navigation-drawer  app left v-model="drawer2" style="max-height: 87vh;">
        <v-img src="@/assets/logo-transparent.png" lazy-src="@/assets/logo-transparent.png" width="150px" class="mx-auto">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#E96324"></v-progress-circular>
            </v-row>
          </template>
        </v-img>


        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ userName }}
            </v-list-item-title>
            <!-- <v-list-item-subtitle>{{ userRole }}</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item-group active-class="border" color="red">
            <v-list-item v-for="(item, index) in items" :key="index" :to="item.link">
              <v-list-item-icon>
                <v-icon small>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!-- signout  -->
        <template v-slot:append>
          <div class="pa-2 mb-1">
            <v-btn block @click="signout()" color="#E96324" class="white--text">
              Logout
              <v-icon rigth small>mdi mdi-logout</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    drawer: "true",
    drawer2: "false",
  }),
  computed: {
    ...mapGetters({ userName: "auth/userName" }),

    items() {
      return [
        {
          title: "System Users",
          icon: "mdi mdi-account-multiple-plus-outline",
          link: "/users",
        },
        {
          title: "Blogs",
          icon: "mdi mdi-file-document-multiple",
          link: "/blogs",
        },
        {
          title: "Promotions",
          icon: "mdi mdi-group",
          link: "/promotions",
        },
      
      ];
    },
  },
  methods: {
    ...mapActions({ signout: "auth/signout" }),
    capitalizeFirstLetter(str) {
      if (!str || typeof str !== "string") {
        return "";
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>
<style scoped>
.list-item-custom {
  height: auto;
}

.avatar-custom {
  width: auto;
  height: auto;
}

.border {
  border: 2px dashed orange;
}
</style>