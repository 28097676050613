import axios from "axios";
import router from "@/router";
import generalMethods from "../../generalMethods"
const state = {
  users: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {
  usersPerPage(state) {
    return state.users.per_page;
  },
  allUsers(state) {
    for(let i=0; i<state.users.data.length; i++){
      state.users.data[i]['updated_at']=generalMethods.methods.formatFriendlyDate(state.users.data[i]['updated_at']);
    }
    return state.users.data;
  },
  selectedSearchResult(state){
    return state.selectedSearchResult;
  }
};
const actions = {
  async setUser({ commit, rootGetters, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "user/setUser",
        {
          created_by: rootGetters["auth/user"].id,
          name: user.name,
          email: user.email,
          password: user.password,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
          },
        }
      )
      .then((response) => {
        user.close();
        dispatch("fetchItems");
        commit(
          "alert/setAlert",
          {
            type: "success",
            message: response.data.message,
            showAlert: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        if ("error" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },

  async fetchItems(
    { commit, rootGetters, rootState },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/getUsers", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setUsers", response.data);
      })
      .catch((error) => {
        if ("error" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
        if ("exit" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
          commit("auth/setAuthenticated", false, { root: true });
          router.push("/");
          setTimeout(function () {
            commit("auth/clearState", rootState, { root: true });
          }, 1500);
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },
  async updateUser({ commit, rootGetters, dispatch, rootState }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "user/updateUser",
        {
          id: user.id,
          name: user.name,
          email: user.email,
          password: user.password,
          created_by: rootGetters["auth/user"].id,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        user.close();
        dispatch("fetchItems");

        commit(
          "alert/setAlert",
          {
            type: "success",
            message: response.data.message,
            showAlert: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        if ("error" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
        if ("exit" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
          commit("auth/setAuthenticated", false, { root: true });
          router.push("/");
          setTimeout(function () {
            commit("auth/clearState", rootState, { root: true });
          }, 1500);
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },
  async deleteUser({ commit, rootGetters }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "user/deleteUser/" + obj.id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        obj.close();
        commit("deleteUser", obj.id);
        commit(
          "alert/setAlert",
          {
            type: "success",
            message: response.data.message,
            showAlert: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        if ("message" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.message,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },
  async search({ commit, rootGetters }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch(() => {})
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  setUsers(state, Users) {
    state.users = Users;
  },
  deleteUser(state, id) {
    state.users.data = state.users.data.filter((user) => user.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.users.current_page = page;
  },
  setPerPage(state, perPage) {
    state.users.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
