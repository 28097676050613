import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.alert.showAlert)?_c(VAlert,{class:_vm.$vuetify.breakpoint.mobile ? 'mx-auto mt-3' : '',style:('z-index: 9999;' +
      (!_vm.$vuetify.breakpoint.mobile
        ? 'position: fixed; top: 20px; right: 20px;'
        : 'position: fixed; bottom: 20px; left: 0px; right: 0px;')),attrs:{"type":_vm.alert.type,"width":!_vm.$vuetify.breakpoint.mobile ? '30%' : '90%',"dismissible":""},on:{"dismissed":_vm.dismissAlert}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]):_vm._e(),(_vm.show)?_c('sideNav'):_vm._e(),_c(VMain,[_c(VSlideXTransition,{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading,"z-index":1000}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }