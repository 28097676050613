import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import blogs from "./modules/blogs";
import promotions from "./modules/promotions";
import loading from "./modules/loading";
import alert from "./modules/alert";


import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  //storing session state incase user refreshes page user token is not lost
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    auth,
    loading,
    alert,
    users,
    blogs,
    promotions,
  },
});
