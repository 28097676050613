

const rules = {
  computed: {
    mobilePrefix() {
      return '+255';
    },
    emailRules() {
      return [
        (v) => !!v || "Enter Email",
        (v) => /.+@.+\..+/.test(v) || "Email Enterd isn't valied",
      ];
    },
    generalEmailRules() {
      return [
        (v) => {
          if (!v) return true; // Allow empty values
          return /.+@.+\..+/.test(v) || "Email entered isn't valid";
        },
      ];
    },
    mobileRules() {
      return [
        (v) => (typeof v === 'string' && v.length > 0) || "Enter Mobile",
        (v) => (/^\d+$/.test(v)) || "Mobile Number should only contain digits",
        (v) => !!v && !v.startsWith('0') || "Mobile Number should not start with 0",
        (v) => (!/\s/.test(v)) || "Mobile Number should not contain spaces",
        (v) => (!(/^\s|\s$/).test(v)) || "Mobile Number should not start or end with spaces",
        (v) => !!v && v.length === 9 || "Mobile Number should have 9 digits",
      ];
    },

    generalRules() {
      return [(v) => !!v || "Field is empty"];
    },
    passwordRules() {
      return [(v) => !!v || "Password required"];
    },
    rePassRules() {
      return [
        (v) => !!v || "Re-enter password",
        (v) => this.editedItem.password == v || "Passwords don't match",
      ];
    },
    fileRules() {
      return [
        (v) => {
          if (!v) {
            return "File is required.";
          }
          if (v.size > 1048576) {
            return "File size exceeds the limit of 1MB";
          }
          const invalid = ["gif", "mp4", "mov", "avi"];
          const fileExtension = v.name.split(".").pop().toLowerCase();

          if (!fileExtension.match(/(jpg|jpeg|png)$/)) {
            return "Invalid file type. Only images jpg, jpeg, png are allowed.";
          }

          if (invalid.includes(fileExtension)) {
            return "Invalid file type. GIFs and videos are not allowed.";
          }

          return true;
        },
      ];
    },
    textAreaRules() {
      return [
        (v) => {
          if (!v) {
            return "Description is required.";
          }
          if (v.length > 250) {
            return "Exceeded Character limit of 250";
          }
          return true;
        },
      ];
    },
  },
  methods: {
    formatWithCommas(number) {
      const parsedNumber = parseFloat(number); // Convert the string to a number
      if (isNaN(parsedNumber)) {
        return number; // Return the input if it's not a valid number
      }
      return parsedNumber.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    },
    formatFriendlyDate(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

      const parsedDate = new Date(dateString);
      const formattedDate = parsedDate.toLocaleDateString("en-US", options);
      return formattedDate;
    },
  },
};
export default rules;
