<template>
  <v-app>
    <v-alert
      :type="alert.type"
      :width="!$vuetify.breakpoint.mobile ? '30%' : '90%'"
      :class="$vuetify.breakpoint.mobile ? 'mx-auto mt-3' : ''"
      dismissible
      @dismissed="dismissAlert"
      v-if="alert.showAlert"
      :style="
        'z-index: 9999;' +
        (!$vuetify.breakpoint.mobile
          ? 'position: fixed; top: 20px; right: 20px;'
          : 'position: fixed; bottom: 20px; left: 0px; right: 0px;')
      "
    >
      {{ alert.message }}
    </v-alert>

    <sideNav v-if="show" />
    <v-main>
      <v-slide-x-transition mode="out-in">
      <router-view />
    </v-slide-x-transition>
    </v-main>
    <v-overlay :value="loading" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import sideNav from "@/components/side_nav.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "App",
  data: () => ({}),
  computed: {
    ...mapGetters({
      alert: "alert/alert",
      loading: "loading/loading",
    }),
    show() {
      if (this.$route.name == "signin") return false;
      else if (this.$route.name == "signup") return false;
      else if (this.$route.name != "signin" && this.$route.name != "signup")
        return true;
    },
  },
  methods: {
    ...mapMutations({
      setAlert: "alert/setAlert",
    }),
    dismissAlert() {
      this.setAlert({ type: "", message: "", showAlert: false });
    },
  },
  components: { sideNav },
};
</script>
