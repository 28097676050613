import axios from "axios";
import router from "@/router";

const state = {
  user: {
    user: {
      id: "",
      name: "",
      email: "",
      email_verified_at: "",
      createdby: "",
      status: "",
      remember_token: "",
      created_at: "",
      updated_at: "",
    },
    token: "",
  },
  authenticated: false,
};
const getters = {
  user(state) {
    return state.user.user;
  },
  userName(state) {
    return state.user.user.name;
  },
  authenticated(state) {
    return state.authenticated;
  },

  token(state) {
    return state.user.token;
  },
};
const actions = {
  async signin({ commit }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(process.env.VUE_APP_API + "signin", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        commit("setUser", response.data);
        commit("setAuthenticated", true);
        router.push("/users");
        commit(
          "alert/setAlert",
          {
            type: "success",
            message: "Login Successfull",
            showAlert: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        if ("error" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },
  signout({ commit, getters, rootState }) {
    commit("loading/loading", true, { root: true });
    // axios
    //   .get(process.env.VUE_APP_API + "signout", {
    //     headers: {
    //       Authorization: "Bearer " + getters.token,
    //       "x-user-id": getters.user.id,
    //     },
    //   })
    //   .then((response) => {
    //     commit("setAuthenticated", false);
    //     router.push("/");
    //     commit("clearState", rootState);
    //     commit(
    //       "alert/setAlert",
    //       {
    //         type: "success",
    //         message: response.data.message,
    //         showAlert: true,
    //       },
    //       { root: true }
    //     );
    //   })
    //   .catch((error) => {
    //     if ("error" in error.response.data) {
    //       commit(
    //         "alert/setAlert",
    //         {
    //           type: "error",
    //           message: error.response.data.error,
    //           showAlert: true,
    //         },
    //         { root: true }
    //       );
    //     } else {
    //       commit(
    //         "alert/setAlert",
    //         {
    //           type: "error",
    //           message: "Something went wrong",
    //           showAlert: true,
    //         },
    //         { root: true }
    //       );
    //     }
    //     if ("exit" in error.response.data) {
    //       commit(
    //         "alert/setAlert",
    //         {
    //           type: "error",
    //           message: error.response.data.error,
    //           showAlert: true,
    //         },
    //         { root: true }
    //       );
    //       commit("setAuthenticated", false);
    //       router.push("/");
    //       setTimeout(function () {
    //         commit("clearState", rootState);
    //       }, 1500);
    //     }
    //   })
    //   .finally(() => {
    //     commit("loading/loading", false, { root: true });
    //     setTimeout(function () {
    //       commit(
    //         "alert/setAlert",
    //         {
    //           type: "",
    //           message: "",
    //           showAlert: false,
    //         },
    //         { root: true }
    //       );
    //     }, 2500);
    //   });
      commit("setAuthenticated", false);
      router.push("/");
      commit("clearState", rootState);
      commit(
        "alert/setAlert",
        {
          type: "success",
          message: "Signed out",
          showAlert: true,
        },
        { root: true }
      );
      commit("loading/loading", false, { root: true });
      setTimeout(function () {
        commit(
          "alert/setAlert",
          {
            type: "",
            message: "",
            showAlert: false,
          },
          { root: true }
        );
      }, 2500);
  },
};
const mutations = {
  setUser(state, data) {
    return (state.user = data);
  },

  setAuthenticated(state, value) {
    state.authenticated = value;
  },

  clearState(state, rootState) {
    // Clear user module rootStates
    state.user = { user: { name: "" } };

    // Clear users module rootStates
    rootState["users"]["users"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["users"]["searchResults"] = [];
    rootState["users"]["selectedSearchResult"] = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
