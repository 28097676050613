
const state = {
  alert: { type: "", message: "", showAlert: false },
};
const getters = {
  alert(state) {
    return state.alert;
  },
};
const actions = {
  executeAlert({ commit }, alert) {
    commit('setAlert', alert);
  },

};
const mutations = {
  setAlert(state, data) {
    state.alert.type = data.type;
    state.alert.message = data.message;
    state.alert.showAlert = data.showAlert;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
