<template>
  <v-card class="mx-auto" height="100%" flat>
    <v-card-text>
      <v-img
        src="@/assets/logo-transparent.png"
        lazy-src="@/assets/logo-transparent.png"
        width="80%"
        class="mx-auto"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="#E96324"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-card-text>
    <v-card-title class="font-weight-bold black--text">
      <v-card color="#E96324" class="mx-auto">
        <!-- <v-img src="@/assets/logo-transparent-text.png" lazy-src="@/assets/logo-transparent-text.png" class="ma-1">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#E96324"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img> -->
      </v-card>
      <br /><br />
    </v-card-title>
    <!-- <v-card-text style="font-size: 20px">
          <span class="black--text"></span>
          <br /><span style="font-size: 15px !important"
            >
            <span class="black--text"></span></span
          >
        </v-card-text> -->
    <v-card-text>
      <signinForm />
    </v-card-text>
  </v-card>
</template>

<script>
import signinForm from "./signinForm.vue";
export default {
  data() {
    return {};
  },
  components: { signinForm },
};
</script>

<style scoped></style>
