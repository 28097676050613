import axios from "axios";
import router from "@/router";
import generalMethods from "../../generalMethods"
const state = {
  promotions: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {
  promotionsPerPage(state) {
    return state.promotions.per_page;
  },
  allPromotions(state) {
    for (let i = 0; i < state.promotions.data.length; i++) {
      state.promotions.data[i]['updated_at'] = generalMethods.methods.formatFriendlyDate(state.promotions.data[i]['updated_at']);
    }
    return state.promotions.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async setPromotion({ commit, rootGetters, dispatch }, promotion) {
    commit("loading/loading", true, { root: true });
    let formData = new FormData();
    formData.append('created_by', rootGetters["auth/user"].id);
    formData.append('title', promotion.title);
    formData.append('brief_description', promotion.brief_description);
    formData.append('promotion', promotion.promotion);
    formData.append('image', promotion.image);
    await axios
      .post(
        process.env.VUE_APP_API + "promotion/setPromotion", formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
          },
        }
      )
      .then((response) => {
        promotion.close();
        dispatch("fetchItems");
        commit(
          "alert/setAlert",
          {
            type: "success",
            message: response.data.message,
            showAlert: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        if ("error" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },

  async fetchItems(
    { commit, rootGetters, rootState },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "promotion/getPromotions", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setPromotions", response.data);
      })
      .catch((error) => {
        if ("error" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
        if ("exit" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
          commit("auth/setAuthenticated", false, { root: true });
          router.push("/");
          setTimeout(function () {
            commit("auth/clearState", rootState, { root: true });
          }, 1500);
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },
  async updatePromotion({ commit, rootGetters, dispatch, rootState }, promotion) {
    commit("loading/loading", true, { root: true });
    let formData = new FormData();
    formData.append('created_by', rootGetters["auth/user"].id);
    formData.append('id', promotion.id);
    formData.append('title', promotion.title);
    formData.append('brief_description', promotion.brief_description);
    formData.append('promotion', promotion.promotion);
    formData.append('image', promotion.image);
    await axios
      .post(
        process.env.VUE_APP_API + "promotion/updatePromotion", formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        promotion.close();
        dispatch("fetchItems");
        commit(
          "alert/setAlert",
          {
            type: "success",
            message: response.data.message,
            showAlert: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        if ("error" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
        if ("exit" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.error,
              showAlert: true,
            },
            { root: true }
          );
          commit("auth/setAuthenticated", false, { root: true });
          router.push("/");
          setTimeout(function () {
            commit("auth/clearState", rootState, { root: true });
          }, 1500);
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },
  async deletePromotion({ commit, rootGetters }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "promotion/deletePromotion/" + obj.id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        obj.close();
        commit("deletePromotion", obj.id);
        commit(
          "alert/setAlert",
          {
            type: "success",
            message: response.data.message,
            showAlert: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        if ("message" in error.response.data) {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: error.response.data.message,
              showAlert: true,
            },
            { root: true }
          );
        } else {
          commit(
            "alert/setAlert",
            {
              type: "error",
              message: "Something went wrong",
              showAlert: true,
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
        setTimeout(function () {
          commit(
            "alert/setAlert",
            {
              type: "",
              message: "",
              showAlert: false,
            },
            { root: true }
          );
        }, 2500);
      });
  },
  async search({ commit, rootGetters }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "promotion/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch(() => { })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  setPromotions(state, promotions) {
    state.promotions = promotions;
  },
  deletePromotion(state, id) {
    state.promotions.data = state.promotions.data.filter((promotion) => promotion.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.orders.current_page = page;
  },
  setPerPage(state, perPage) {
    state.orders.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
