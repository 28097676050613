<template>
  <div>
    <!-- desktop -->
    <div v-if="!$vuetify.breakpoint.mobile">
      <desktop />
    </div>
    <!-- mobile -->
    <div v-if="$vuetify.breakpoint.mobile">
      <mobile />
    </div>
   
  </div>
</template>

<script>
import desktop from "@/components/signin/desktop.vue";
import mobile from "@/components/signin/mobile.vue";

export default {
  components: {
    desktop,
    mobile,
  },

};
</script>
<style scoped>
</style>
