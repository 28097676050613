import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",attrs:{"height":"100%","flat":""}},[_c(VCardText,[_c(VImg,{staticClass:"mx-auto",attrs:{"src":require("@/assets/logo-transparent.png"),"lazy-src":require("@/assets/logo-transparent.png"),"width":"80%"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#E96324"}})],1)]},proxy:true}])})],1),_c(VCardTitle,{staticClass:"font-weight-bold black--text"},[_c(VCard,{staticClass:"mx-auto",attrs:{"color":"#E96324"}}),_c('br'),_c('br')],1),_c(VCardText,[_c('signinForm')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }