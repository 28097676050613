import Vue from 'vue'
import VueRouter from 'vue-router'
import signin from "../views/signin.vue";
import store from "@/store";


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "signin",
    component: signin,
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/blogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Admin Panel Users",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/users.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/promotions",
    name: "promotions",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/promotions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  routes
});

// Define the handleBackButton function
const handleBackButton = () => {
  router.beforeEach((to, from, next) => {
    if (window.event.type === 'popstate' && from.name === 'signin') {
      next(false);
    } else {
      next();
    }
  });
};
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/authenticated"]) {
      next();
    } else {
      next({ name: "signin" });
      store.dispatch("alert/executeAlert", {
        type: "error",
        message: "You need to be authenticated.",
        showAlert: true,
      });
    }
  } else {
    if (store.getters["auth/authenticated"] && (to.name === 'signin' || to.name === 'signup')) {
      next({ path: "/users" });
      store.dispatch("alert/executeAlert", {
        type: "info",
        message: "You are already authenticated.",
        showAlert: true,
      });
    } else {
      next();
    }
  }
});

export default router
